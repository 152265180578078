import { useRef, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useQuery } from 'react-query';
import { If } from 'src/components/If';
import { PlanCard } from 'src/components/PlanCard';
import { PlanSwitch } from 'src/components/PlanSwitch';
import MetaTags from 'src/components/Seo/MetaTags';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getSeoPlans } from 'src/services/misc';
import { comparePlansImage, plans } from 'src/services/plans';
import { Plan } from 'src/types/plans';
import { Media } from 'src/types/strapi';
import { getLargestImageUrl } from 'src/utils/image';
import { ScrollToTop } from 'src/utils/scroll';

import PlansFAQ from './PlansFAQ';
import { SectionPlans } from './styles';
import OurClients from '../Home/OurClients';

type ComparePlansImage = {
  comparePlans: {
    image: Media;
    altText: string;
  };
};

function Plans() {
  const { auth } = useAuthContext();
  const plansRef = useRef<null | HTMLInputElement>(null);
  const [showComparePlans, setShowComparePlans] = useState(false);
  const [period, setPeriod] = useState<string>('year');
  const { isLoading, data } = useQuery<Plan[]>('plans', plans);
  const { data: imageData } = useQuery<ComparePlansImage>(
    'comparePlansImage',
    comparePlansImage,
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const planOrder = ['free', 'individual', 'team', 'vip'];
  const sortByType = (a: Plan, b: Plan) =>
    planOrder.indexOf(a.type) - planOrder.indexOf(b.type);

  const [subscribers, setSubscribers] = useState<number>(0);

  const isDowngrade = (plan: Plan) => {
    if (!auth.user?.subscription?.plan) return false;
    const planOrder = ['demo', 'free', 'individual', 'team', 'vip'];
    return (
      planOrder.indexOf(plan.type) <
      planOrder.indexOf(auth.user.subscription.plan.type)
    );
  };

  const isUserDowngrade = (plan: Plan) => {
    return (
      plan.type === 'team' &&
      auth.user?.subscription?.plan?.type === 'team' &&
      (auth?.user?.subscription?.activeSubscribers ?? 0) > subscribers
    );
  };

  const isPeriodDowngrade = (selectedPeriod: string) => {
    const priceId = auth.user?.subscription?.priceId;
    const currentPeriod = auth.user?.subscription?.plan?.prices?.find(
      (price) => price.priceId === priceId,
    )?.interval;

    return (
      currentPeriod !== selectedPeriod &&
      auth.user?.subscription?.plan?.type !== 'demo' &&
      auth.user?.subscription?.plan?.type !== 'free'
    );
  };

  const isChangeAllowed = (plan: Plan) => {
    if (!auth.isLoggedIn) return true;
    if (isDowngrade(plan)) return false;
    if (isUserDowngrade(plan)) return false;
    if (isPeriodDowngrade(period)) return false;
    return true;
  };

  const getButtonLabel = (plan: Plan) => {
    if (
      plan?.type === 'free' &&
      auth.user?.subscription?.plan?.type !== 'demo'
    ) {
      return 'Iniciar teste gratuito';
    }
    if (!auth.isLoggedIn) return 'Contratar plano';
    if (!isChangeAllowed(plan)) return 'Falar com time comercial';
    return ['free', 'demo'].includes(auth.user?.subscription?.plan?.type ?? '')
      ? 'Contratar plano'
      : 'Alterar plano';
  };

  const handleSetSubscribers = (value: number) => {
    setSubscribers(value);
  };

  const { data: seoPlans } = useQuery('seo-plans', getSeoPlans);

  if (isLoading) return null;
  return (
    <>
      <MetaTags
        title="Planos"
        description="Seja bem-vindo a Uqbar, a mais importante fonte de educação, conexão e inteligência do mercado de finanças estruturadas."
        seo={seoPlans ? seoPlans.data?.seo : undefined}
      />
      <ScrollToTop>
        <SectionPlans component="section">
          <Container
            maxWidth="lg"
            sx={{
              paddingTop: isMobile ? '250px' : '175px',
              paddingBottom: '100px',
            }}
            ref={plansRef}>
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: 38,
                fontWeight: 700,
                mx: 'auto',
                maxWidth: '530px',
              }}>
              Encontre o plano certo para você e o seu negócio.
            </Typography>
            <Box
              sx={{
                textAlign: 'center',
                marginTop: 4,
                marginBottom: 7.5,
                mx: 'auto',
                maxWidth: '357px',
              }}>
              <If condition={auth.isLoggedIn}>
                <Typography>
                  Escolha um plano e comece a utilizar nossa plataforma agora
                  mesmo.
                </Typography>
              </If>
              <If condition={!auth.isLoggedIn}>
                <Typography>
                  Cadastre-se e tenha 7 dias de demostração gratuita de todos os
                  benefícios da plataforma.
                </Typography>
              </If>
            </Box>
            <Stack
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                borderRadius: '11.5px',
                padding: '16px',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 5,
                }}>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 700,
                    fontSize: '15px',
                    lineHeight: '18px',
                    color: '#333333',
                  }}>
                  Faturado anualmente
                </Typography>
                <PlanSwitch
                  color="secondary"
                  checked={period === 'month'}
                  onChange={() => {
                    setPeriod((prevState) =>
                      prevState === 'year' ? 'month' : 'year',
                    );
                  }}
                  inputProps={{ 'aria-label': 'trocar tipo de plano' }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 700,
                    fontSize: '15px',
                    lineHeight: '18px',
                    color: '#333333',
                  }}>
                  Faturado mensalmente
                </Typography>
              </Box>
              <Grid container spacing={1} justifyContent="center">
                {data?.sort(sortByType)?.map((plan: Plan) => {
                  if (
                    !auth.isLoggedIn ||
                    plan.type !== 'free' ||
                    (auth.user?.subscription?.status === 'Expirado' &&
                      plan.type === 'free')
                  )
                    return (
                      <Grid item xs={12} sm={12} lg={3} key={`plan-${plan.id}`}>
                        <PlanCard
                          plan={plan}
                          period={period}
                          buttonLabel={getButtonLabel(plan)}
                          isDowngrade={!isChangeAllowed(plan)}
                          handleSetSubscribers={handleSetSubscribers}
                        />
                      </Grid>
                    );
                })}
              </Grid>
            </Stack>
            <Box sx={{ marginTop: '24px' }}>
              <Typography sx={{ textAlign: 'center', fontSize: '14px' }}>
                *Gerenciamento de usuários e exportação de arquivos não estão
                inclusos no teste gratuito.
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              marginTop={8}>
              <Button
                variant="text"
                color="inherit"
                sx={{
                  whiteSpace: 'nowrap',
                  width: 'auto',
                }}
                endIcon={
                  showComparePlans ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
                onClick={() => setShowComparePlans((prevState) => !prevState)}>
                COMPARAR PLANOS
              </Button>
              {showComparePlans && imageData?.comparePlans && (
                <img
                  width="100%"
                  height="100%"
                  src={getLargestImageUrl(imageData.comparePlans.image)}
                  alt={imageData.comparePlans.altText}
                />
              )}
            </Box>
            <Stack gap={5} maxWidth="1109px" mx="auto" my={9}>
              <Typography
                sx={{ fontSize: '32px', fontWeight: 700, textAlign: 'center' }}>
                A plataforma Uqbar oferece milhares de operações, dados,
                treinamentos e muito mais.
              </Typography>
              <Typography sx={{ fontSize: '20px', textAlign: 'center' }}>
                Contamos com uma rede de networking e marketplace entre empresas
                que tornam a experiência dentro da plataforma única.
              </Typography>
            </Stack>
            <PlansFAQ />
          </Container>
          <Box sx={{ background: '#F1F1F1' }}>
            <OurClients />
          </Box>
        </SectionPlans>
      </ScrollToTop>
    </>
  );
}

export default Plans;

import { Add, Person, Remove } from '@mui/icons-material';
import { Badge, IconButton, useTheme } from '@mui/material';

type UserCounterProps = {
  max: number;
  userCounter: number;
  handleSetUserCounter: (value: number) => void;
  min: number;
};

export function UsersCounter({
  max,
  userCounter,
  handleSetUserCounter,
  min = 3,
}: UserCounterProps) {
  const theme = useTheme();
  return (
    <>
      <IconButton
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: theme.palette.secondary.main,
          color: 'white',
          width: '1em',
          height: '1em',
          margin: theme.spacing(1, 1),
          marginLeft: 0,
          '&:hover': {
            backgroundColor: theme.palette.secondary.main,
          },
        }}
        color="secondary"
        disabled={userCounter === min}
        onClick={() => handleSetUserCounter(userCounter - 1)}>
        <Remove />
      </IconButton>
      <Person
        sx={{
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: '28px',
          color: theme.palette.common.black,
          marginTop: theme.spacing(0.5),
        }}
      />
      <Badge
        sx={{
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: '18px',
          color: theme.palette.common.black,
          marginTop: theme.spacing(0.75),
        }}>
        {userCounter}
      </Badge>
      <IconButton
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: theme.palette.secondary.main,
          color: 'white',
          width: '1em',
          height: '1em',
          '&:hover': {
            backgroundColor: theme.palette.secondary.main,
          },
          margin: theme.spacing(1, 1),
        }}
        color="secondary"
        disabled={userCounter === max}
        onClick={() => handleSetUserCounter(userCounter + 1)}>
        <Add />
      </IconButton>
    </>
  );
}

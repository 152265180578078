import qs from 'qs';
import { api } from 'src/api/api';
import { StrapiResponse } from 'src/types/http';
import { CriUnderlyingDebtor, CriWalletPDD } from 'src/types/operation';

export const getCriWalletPDD = async (operationId: string) => {
  const query = qs.stringify(
    {
      sort: ['dataReferencia:desc'],
      filters: {
        operation: {
          operationId,
        },
      },
      populate: ['operation'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await api.get<StrapiResponse<CriWalletPDD[]>>(
    `/meta-cri-carteira-de-recebiveis-e-pdds?${query}`,
  );
  return data;
};

export const getCriUnderlyingDebtors = async (operationId: string) => {
  const query = qs.stringify({
    filters: {
      operationId,
    },
  });
  const { data } = await api.get<StrapiResponse<CriUnderlyingDebtor[]>>(
    `/meta-cri-cedentes-e-devedores?${query}`,
  );
  return data;
};

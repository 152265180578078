import { useEffect, useState } from 'react';

import {
  Alert,
  Divider,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  addMonths,
  differenceInMonths,
  format,
  min,
  parseISO,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import CardDemonstration from 'src/components/CardDemonstration/CardDemonstration';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getPayWallBanner } from 'src/services/informative';
import { getFundsHistory } from 'src/services/operation';
import { FidcFundHistory } from 'src/types/operation';

import { Chart, ChartData } from './Chart';
import {
  Fund,
  SelectClassSerieValue,
  SelectFundAndSerie,
} from './SelectFundAndSerie';
import { notify } from '../Calculator/utils/handleNotify';

type FlattenRentability = {
  formattedDate: string;
  originalDate: Date;
  value: number | null;
};

type FlattenFundHistory = {
  fundName: string;
  color: string;
  rentabilityHistory: FlattenRentability[];
};

type MonthRentability = {
  month: string;
  fundData: {
    fundName: string;
    rentability: FlattenRentability['value'];
  }[];
};

type FundSelected = Fund & {
  highlightColor: string;
  classSerie: string;
  rentabilityHistory: {
    formattedDate: string;
    value: number | null;
  }[];
};

type LocationState = {
  funds: Fund[];
};

type PeriodOption = number | null;

const PERIOD_OPTIONS = [
  {
    label: '12 Meses',
    value: 12,
  },
  {
    label: '24 Meses',
    value: 24,
  },
  {
    label: 'Tudo',
    value: null,
  },
];

const OperationProfitabilityComparison = (): JSX.Element => {
  const theme = useTheme();
  const location = useLocation<LocationState>();
  const { auth } = useAuthContext();

  const HIGHLIGHT_COLORS = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
  ];

  const hasPermission =
    !!auth?.user?.subscription?.plan?.permissions?.comparar_fundos;

  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [missingDataMessage, setMissingDataMessage] = useState<string>('');
  const [periodSelected, setPeriodSelected] = useState<PeriodOption>(12);
  const [fundsSelected, setFundsSelected] = useState<FundSelected[]>([]);

  const { data: contentPayWallBanner } = useQuery(
    'paywall',
    () => getPayWallBanner('compareFunds'),
    {
      enabled: auth?.isLoading,
    },
  );

  useEffect(() => {
    const funds: FundSelected[] = location?.state?.funds.map((fund, index) => ({
      classSerie: '',
      rentabilityHistory: [],
      highlightColor: HIGHLIGHT_COLORS[index],
      ...fund,
    }));

    setFundsSelected(funds);
  }, [location.state.funds]);

  const fundSelectedKey = fundsSelected
    .map(({ cnpj, classSerie }) => cnpj + classSerie)
    .join(',');

  const { isLoading: isLoadingFundHistory } = useQuery(
    ['fund-histories', fundSelectedKey, periodSelected],
    () =>
      getFundsHistory({
        CNPJs: fundsSelected.map(({ cnpj }) => cnpj),
        period: periodSelected,
      }),
    {
      onSuccess: (histories) => {
        let startDate = new Date();

        if (periodSelected) {
          startDate = subMonths(startDate, periodSelected);
        } else {
          startDate = min(
            histories.map((history) =>
              typeof history.data_competencia === 'string'
                ? parseISO(history.data_competencia)
                : history.data_competencia,
            ),
          );
        }

        const flattenFunds = flattenFundHistories(histories);

        const monthsArray = generateMonthsArray(startDate);

        const monthRentability = fillRentabilityByMonth(
          monthsArray,
          flattenFunds,
        );

        const datasets = generateChartDataSets(monthRentability);

        generateMissingDataMessage(monthRentability);

        setChartData(datasets);
      },
      onError: () => notify('Erro ao buscar histórico do fundo', 'error'),
    },
  );

  // Function to generate missing data messages for funds
  const generateMissingDataMessage = (monthRentability: MonthRentability[]) => {
    const missingDataMessages: string[] = [];

    // Creates a dictionary to track rentability by fund and month
    const rentabilityDict = monthRentability.reduce(
      (acc, { month, fundData }) => {
        fundData.forEach(({ fundName, rentability }) => {
          if (!acc[fundName]) acc[fundName] = {};
          acc[fundName][month] = rentability;
        });
        return acc;
      },
      {} as Record<string, Record<string, number | null>>,
    );

    fundsSelected.forEach((fund) => {
      const missingMonths: string[] = [];

      monthRentability.forEach((monthData) => {
        const fundRentability = rentabilityDict[fund.name]?.[monthData.month];

        // Rentability can be 0
        if (fundRentability === null || fundRentability === undefined) {
          missingMonths.push(monthData.month);
        }
      });

      // If missing data is found, a message is generated
      if (missingMonths.length > 0) {
        const message = `${fund.name}: ${fund.classSerie} não possui dados disponíveis no período completo`;
        missingDataMessages.push(message);
      }
    });

    setMissingDataMessage(missingDataMessages.join('. '));
  };

  // Function to fill rentability data by month
  const fillRentabilityByMonth = (
    monthsArray: Date[],
    flattenFunds: FlattenFundHistory[],
  ): MonthRentability[] => {
    // Creates a dictionary of rentability data grouped by month
    const rentabilityDict = flattenFunds.reduce(
      (acc, fund) => {
        fund.rentabilityHistory.forEach(({ originalDate, value }) => {
          const formattedMonth = format(originalDate, 'MM/yyyy', {
            locale: ptBR,
          });
          if (!acc[formattedMonth]) acc[formattedMonth] = [];
          acc[formattedMonth].push({
            fundName: fund.fundName,
            rentability: value,
          });
        });
        return acc;
      },
      {} as Record<string, { fundName: string; rentability: number | null }[]>,
    );

    // Returns the data formatted with the month and corresponding rentability data for each fund
    return monthsArray.map((month) => {
      const formattedMonth = format(month, 'MM/yyyy', { locale: ptBR });
      const fundData = rentabilityDict[formattedMonth] || [];

      return {
        month: formattedMonth,
        fundData,
      };
    });
  };

  // Function to generate chart datasets based on rentability data by months
  const generateChartDataSets = (rentabilityByMonth: MonthRentability[]) => {
    // Creates a dictionary where rentability data is grouped by fund name and month
    const rentabilityDict = rentabilityByMonth.reduce(
      (acc, monthData) => {
        monthData.fundData.forEach(({ fundName, rentability }) => {
          if (!acc[fundName]) acc[fundName] = [];
          acc[fundName].push({ x: monthData.month, y: rentability });
        });
        return acc;
      },
      {} as Record<string, { x: string; y: number | null }[]>,
    );

    return fundsSelected.map((fund) => {
      const fundRentabilityData = rentabilityDict[fund.name] || [];

      return {
        name: fund.name,
        data: fundRentabilityData,
        color: fund.highlightColor,
      };
    }) as ChartData[];
  };

  // Function to generate an array of months from a start date to the current date
  const generateMonthsArray = (startDateInput: Date): Date[] => {
    const currentDate = startOfMonth(new Date());
    const startDate = startOfMonth(startDateInput);
    const monthsArray: Date[] = [];

    const totalMonths = differenceInMonths(currentDate, startDate);

    for (let i = 0; i <= totalMonths; i++) {
      monthsArray.push(addMonths(startDate, i));
    }

    return monthsArray;
  };

  // Function to flatten fund histories and organize them by fund and series class
  const flattenFundHistories = (
    fundHistories: FidcFundHistory[],
  ): FlattenFundHistory[] => {
    // Creates a dictionary where the key is a combination of fund CNPJ and series class
    const historyDict = fundHistories.reduce(
      (acc, history) => {
        const key = `${history.cnpj_fundo}-${history.classe_serie}`;

        if (!acc[key]) acc[key] = [];

        acc[key].push(history);

        return acc;
      },
      {} as Record<string, FidcFundHistory[]>,
    );

    return fundsSelected.map((fund) => {
      const key = `${fund.cnpj}-${fund.classSerie}`;
      const historiesFounded = historyDict[key] || [];

      const rentabilityHistorySorted = historiesFounded.sort(
        (a, b) =>
          new Date(a.data_competencia).getTime() -
          new Date(b.data_competencia).getTime(),
      );

      // Formats each history entry before returning the flattened data
      const rentabilityHistory = rentabilityHistorySorted.map(
        ({ data_competencia, rentabilidade_mensal }) => {
          const competenceDate = new Date(data_competencia);

          return {
            formattedDate: format(competenceDate, 'MM/yyyy', {
              locale: ptBR,
            }),
            originalDate: competenceDate,
            value: rentabilidade_mensal,
          };
        },
      );

      return {
        fundName: fund.name,
        color: fund.highlightColor,
        rentabilityHistory,
      };
    });
  };

  const handleChangeClassSerie = async (
    value: SelectClassSerieValue,
    fundIndex: number,
  ) => {
    setFundsSelected((currentFunds) => {
      const currentFundsCopy = [...currentFunds];

      currentFundsCopy[fundIndex] = {
        ...currentFundsCopy[fundIndex],
        ...value,
        rentabilityHistory: [],
      };

      return currentFundsCopy;
    });

    setMissingDataMessage('');
    setChartData([]);
  };

  const handleClickPeriod = async (period: PeriodOption) => {
    setPeriodSelected(period);
  };

  return (
    <Paper
      square
      elevation={0}
      sx={{
        padding: theme.spacing(3, 4),
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(3, 2),
        },
        mx: 'auto',
      }}>
      {hasPermission ? (
        <Stack>
          <Stack gap={1} marginBottom={2}>
            <Typography variant="h4">Histórico de rentabilidade</Typography>
            <Divider />
          </Stack>
          <Stack flexDirection="column" gap={2}>
            {fundsSelected.map(
              ({ cnpj, name, highlightColor }, selectedFundIndex) => (
                <SelectFundAndSerie
                  key={cnpj}
                  label={`Fundo ${selectedFundIndex + 1}`}
                  color={highlightColor}
                  onSelectClassSerie={(value) =>
                    handleChangeClassSerie(value, selectedFundIndex)
                  }
                  value={{ cnpj, name }}
                />
              ),
            )}
          </Stack>
          <Stack>
            {missingDataMessage && (
              <Stack mt={2}>
                <Alert severity="warning">Atenção: {missingDataMessage}.</Alert>
              </Stack>
            )}
            <Stack my={2}>
              <Alert severity="info">
                Os dados de rentabilidade das cotas de FIDC apresentados são
                provenientes dos informes mensais disponibilizados à Comissão de
                Valores Mobiliários (CVM). A Uqbar não se responsabiliza por
                eventuais divergências ou inconsistências nas informações
                fornecidas pelos administradores dos fundos.
              </Alert>
            </Stack>
            <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
              {PERIOD_OPTIONS.map((option) => (
                <Typography
                  key={option.value}
                  sx={{
                    cursor: 'pointer',
                    textDecoration:
                      option.value === periodSelected ? 'underline' : 'none',
                  }}
                  variant="caption"
                  onClick={() =>
                    handleClickPeriod(option.value as PeriodOption)
                  }>
                  {option.label}
                </Typography>
              ))}
            </Stack>

            <div
              style={{
                filter: isLoadingFundHistory ? 'blur(5px)' : 'none',
                pointerEvents: isLoadingFundHistory ? 'none' : 'all',
              }}>
              <Chart chartData={chartData} />
            </div>
          </Stack>
        </Stack>
      ) : (
        <CardDemonstration
          title={contentPayWallBanner?.profitability?.title}
          subTitle={contentPayWallBanner?.profitability?.subTitle}
          labelButton={contentPayWallBanner?.profitability?.labelButton}
          url={contentPayWallBanner?.profitability?.url}
        />
      )}
    </Paper>
  );
};

export default OperationProfitabilityComparison;

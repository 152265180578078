import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { UnderlyingDebtor } from 'src/types/operation';
import { formatCNPJ } from 'src/utils/document';

type UnderlyingDebtorCardCardProps = {
  debtor: UnderlyingDebtor;
  isLoggedIn: boolean;
  hasLink: boolean;
};

export function UnderlyingDebtorCard({
  debtor,
  isLoggedIn,
  hasLink,
}: UnderlyingDebtorCardCardProps) {
  return (
    <Card square sx={{ display: 'flex', minHeight: '7rem' }}>
      <Stack
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%">
        <CardContent
          sx={{
            padding: '20px',
            '&:last-child': { paddingBottom: '20px' },
            minHeight: '120px',
          }}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '14px',
            }}>
            {debtor.nome_empresarial ?? debtor.name}
          </Typography>
          <Box display="flex" alignItems="center" mt={1}>
            <Typography
              sx={(theme) => ({
                whiteSpace: 'nowrap',
                '-webkit-filter': isLoggedIn ? 'none' : 'blur(5px)',
                color: hasLink
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main,
                textDecoration: hasLink ? 'underline' : 'none',
              })}>
              {isLoggedIn
                ? `CNPJ - ${formatCNPJ(debtor.cnpj)}`
                : '000.000.000/0000-00'}
            </Typography>
          </Box>
        </CardContent>
      </Stack>
    </Card>
  );
}

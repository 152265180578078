import { useState } from 'react';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { If } from 'src/components/If';
import SubTitle from 'src/components/UI/Subtitle';
import TabTemplate from 'src/components/UI/TabTemplate';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getSubscriptionBanner } from 'src/services/plans';
import { createSessionStripe } from 'src/services/portal';
import { cancelInvitedUser } from 'src/services/subscription';

const Banner = ({ url }: { url: string }) => {
  return (
    <Box marginTop="16px">
      <img src={url} style={{ width: '100%' }} />
    </Box>
  );
};

const getType = (isOwner?: boolean) => {
  return isOwner ? 'subscriptionBanner' : 'invitedSubscriptionBanner';
};

function Signature() {
  const { auth, reloadUser } = useAuthContext();
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);

  const subscription = !auth.user?.isOwner
    ? auth.user?.subscription
    : auth.user?.nextSubscription ?? auth.user?.subscription;

  const isFreePlan =
    subscription?.plan?.type &&
    ['free', 'demo'].includes(subscription?.plan.type);

  const createSession = useMutation('createSession', createSessionStripe, {
    onSuccess: ({ url }) => {
      window.open(url, '_blank', 'noreferrer');
    },
  });

  const cancelInvitedUserMutation = useMutation(cancelInvitedUser, {
    onSuccess: async () => {
      history.push('/');
      reloadUser();
    },
  });

  const { data, isLoading } = useQuery(
    'subscriptionBanner',
    () =>
      getSubscriptionBanner({
        id: subscription?.plan.id,
        type: getType(auth?.user?.isOwner),
      }),
    {
      enabled: !auth.isLoading && !!subscription?.plan.id,
    },
  );

  const handleClick = () => {
    if (isFreePlan) {
      history.push('/planos');
      return;
    }

    if (!auth.user?.isOwner) {
      setOpenDialog(true);
      return;
    }

    createSession.mutate({ type: 'customer_portal' });
  };

  const handleRedirectLink = (type: string) => {
    createSession.mutate({ type });
  };

  if (isLoading || !data) return <CircularProgress />;

  return (
    <Box>
      <TabTemplate
        title="Assinatura"
        titleIcon={<CreditCardIcon />}
        tabContent={[]}
        tabIndex={0}
        handleChange={() => {}}
        breadcrumbs1="Assinatura"
      />
      <Paper elevation={2} sx={{ marginTop: 9, padding: '56px 30px 10px' }}>
        <SubTitle
          title="Dados da assinatura"
          buttons={
            auth.user?.isOwner
              ? []
              : [
                  {
                    label: 'Desvincular conta',
                    handle: handleClick,
                    disabled: false,
                  },
                ]
          }
        />

        <Box sx={{ padding: '38px 30px 30px' }}>
          <Grid container spacing={2}>
            {!auth.user?.isOwner && (
              <>
                <Grid item container>
                  <Grid item xs={12} sm={4} lg={3}>
                    <Typography fontSize={14} fontWeight={700}>
                      Administrador da conta
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} lg={9}>
                    <Typography fontSize={14}>
                      {auth.user?.subscription.owner?.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item xs={12} sm={4} lg={3}>
                    <Typography fontSize={14} fontWeight={700}>
                      E-mail do administrador
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} lg={9}>
                    <Typography fontSize={14}>
                      {auth.user?.subscription.owner?.email}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item container>
              <Grid item xs={12} sm={4} lg={3}>
                <Typography fontSize={14} fontWeight={700}>
                  Plano
                </Typography>
              </Grid>
              <Grid
                item
                container
                alignItems="baseline"
                spacing={1}
                xs={12}
                sm={8}
                lg={9}>
                <Grid item>
                  <Typography fontSize={14}>
                    {subscription?.plan.name}
                  </Typography>
                </Grid>
                {isFreePlan && !auth.user?.nextSubscription && (
                  <Grid item>
                    <Link
                      sx={{
                        cursor: 'pointer',
                        fontSize: '12px',
                        fontWeight: 600,
                      }}
                      onClick={() => handleRedirectLink('subscription_update')}>
                      Alterar assinatura
                    </Link>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {!isFreePlan && subscription?.endAt && (
              <Grid item container>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography fontSize={14} fontWeight={700}>
                    Vigência do plano
                  </Typography>
                </Grid>
                <Grid
                  container
                  alignItems="baseline"
                  spacing={1}
                  item
                  xs={12}
                  sm={8}
                  lg={9}>
                  <Grid item>
                    <Typography fontSize={14} textTransform="capitalize">
                      {format(parseISO(subscription.endAt), 'MMMM/yyyy', {
                        locale: ptBR,
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {auth.user?.isOwner && (
              <Grid item container>
                <Grid item xs={12} sm={4} lg={3}>
                  <Typography fontSize={14} fontWeight={700}>
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  <Typography fontSize={14}>{subscription?.status}</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          {auth.user?.isOwner && (
            <Typography
              fontSize={14}
              sx={{ margin: '48px 0px 52px', color: '#c1c1c1' }}>
              {isFreePlan
                ? `Atualmente você está no plano ${subscription.plan.name.toLocaleLowerCase()}, ao clicar em “ver planos” você pode contratar um plano e ter acesso a recursos exclusivos na plataforma.`
                : 'Para alterar seu plano, administrar a quantidade de acessos, atualizar forma de pagamento ou realizar o cancelamento da sua assinatura, envie um e-mail para comercial@uqbar.com.br'}
            </Typography>
          )}

          {data[getType(auth.user?.isOwner)]?.image?.url && (
            <Banner url={data[getType(auth.user?.isOwner)].image.url} />
          )}
        </Box>
      </Paper>
      <If condition={!auth.user?.isOwner && openDialog}>
        <ConfirmationDialog
          title={`Você está no plano ${auth.user?.subscription.plan.name}`}
          subTitle="Ao desvincular você será movido para o plano gratuito e perderá os benefícios do plano atual do seu administrador. Desejar continuar?"
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          handleAction={() => cancelInvitedUserMutation.mutate()}
        />
      </If>
    </Box>
  );
}

export default Signature;

import _ from 'lodash';
import ReactApexChart from 'react-apexcharts';

export type ChartData = {
  name: string;
  data: { x: string; y: number | null }[];
  color: string;
};

type YAxis = Omit<ChartData, 'data'> & {
  data: (number | null)[];
};

type XAxis = string[];

interface ChartProps {
  chartData: ChartData[];
}

export const Chart = ({ chartData }: ChartProps): JSX.Element => {
  const { yAxes, xAxes } = chartData.reduce(
    (acc, { color, data, name }) => {
      acc.yAxes.push({
        name,
        color,
        data: data.map(({ y }) => y),
      });

      acc.xAxes = acc.xAxes.concat(data.map(({ x }) => x));

      return acc;
    },
    { yAxes: [] as YAxis[], xAxes: [] as XAxis[] },
  );

  return (
    <ReactApexChart
      series={yAxes}
      options={{
        chart: {
          height: 500,
          type: 'line',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        title: {
          text: '',
          align: 'left',
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: _.uniq(xAxes),
        },
      }}
    />
  );
};
